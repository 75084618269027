export default {
  namespaced: true,
  state: {
    idEvaluation: null,
    idEvaluationApply: null,
    idEvaluationApplyEdit: null,
  },
  getters: {
    getIdEvaluation: state => state.idEvaluation,
    getIdEvaluationApply: state => state.idEvaluationApply,
    getIdEvaluationApplytoEdit: state => state.idEvaluationApplyEdit,
  },
  mutations: {
    setIdEvaluation(state, idEvaluation) {
      state.idEvaluation = idEvaluation
    },
    setIdEvaluationApply(state, idEvaluationApply) {
      state.idEvaluationApply = idEvaluationApply
    },
    setIdEvaluationApplytoEdit(state, idEvaluationApplyEdit) {
      state.idEvaluationApplyEdit = idEvaluationApplyEdit
    },
  },
}
