import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profiles/Profiles.vue'),
      meta: {
        requiresAuth: true,
        analystAuth: true,
      },
    },
    {
      path: '/profile-info',
      name: 'profile-info',
      component: () => import('@/views/Profiles/ProfileInfo/ProfileInfo.vue'),
      meta: {
        pageTitle: 'Profile Info',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Profile Info',
          },
        ],
        agentAuth: true,
      },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home/Home.vue'),
      meta: {
        requiresAuth: true,
        analystAuth: true,
      },
    },
    {
      path: '/add-evaluations',
      name: 'add-evaluations',
      component: () => import('@/views/Home/AddEvaluations/AddEvaluations.vue'),
      meta: {
        pageTitle: 'Evaluation',
        requiresAuth: true,
        analystAuth: true,
        breadcrumb: [
          {
            text: 'Evaluation',
          },
        ],
      },
    },
    {
      path: '/evaluation-dashboard',
      name: 'evaluation-dashboard',
      component: () => import('@/views/Home/EvaluationDashBoard/EvaluationDashBoard.vue'),
      meta: {
        pageTitle: 'Evaluation Dashboard',
        requiresAuth: true,
        analystAuth: true,
        breadcrumb: [
          {
            text: 'Evaluation Dashboard',
          },
        ],
      },
    },
    {
      path: '/evaluation-apply',
      name: 'evaluation-apply',
      component: () => import('@/views/Profiles/ProfileInfo/EvaluationApply/EvaluationApply.vue'),
      meta: {
        pageTitle: 'Evaluation Apply',
        requiresAuth: true,
        analystAuth: true,
        breadcrumb: [
          {
            text: 'Profile Info',
            active: true,
          },
          {
            text: 'Evaluation Apply',
          },
        ],
      },
    },
    {
      path: '/individual-timesheet',
      name: 'individual-timesheet',
      component: () => import('@/views/TimeSheet/Individual.vue'),
      meta: {
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      path: '/breakdown-timesheet',
      name: 'breakdown-timesheet',
      component: () => import('@/views/TimeSheet/BreakDown.vue'),
      meta: {
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      path: '/candidate-tracking',
      name: 'candidate-tracking',
      component: () => import('@/views/CandidateTracking/CandidateTracking.vue'),
      meta: {
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      path: '/candidate-profile',
      name: 'candidate-profile',
      component: () => import('@/views/CandidateTracking/CandidateProfile.vue'),
      meta: {
        pageTitle: 'Candidate Profile',
        requiresAuth: true,
        adminAuth: true,
        breadcrumb: [
          {
            text: 'Candidate Tracking',
            active: true,
          },
          {
            text: 'Candidate Profile',
          },
        ],
      },
    },
    {
      path: '/logs',
      name: 'logs',
      component: () => import('@/views/Logs/Logs.vue'),
      meta: {
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      path: '/catalogs',
      name: 'catalogs',
      component: () => import('@/views/Settings/Catalogs/Catalogs.vue'),
      meta: {
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/two-factor-auth',
      name: 'two-factor-auth',
      component: () => import('@/views/TwoFactorAuth.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/system/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const isLoggedIn = store.getters['user-auth/isLoggedIn']
    const profile = store.getters['user-auth/getStaffProfile']
    if (!isLoggedIn) {
      next('/')
    } else if (to.meta.adminAuth) {
      if (profile === 1) {
        next()
      } else {
        next('/profile')
      }
    } else if (to.meta.analystAuth) {
      if (profile === 2 || profile === 1) {
        next()
      } else {
        next('/profile-info')
      }
    } else if (to.meta.agentAuth) {
      if (profile === 3 || profile === 1 || profile === 2) {
        next()
      } else {
        next('/profile-info')
      }
    }
  } else {
    next()
  }
})

export default router
