export default {
  namespaced: true,
  state: {
    user: {},
    token: '',
  },
  getters: {
    getUser: state => state.user,
    isLoggedIn: state => !!state.token,
    getStaffProfile: state => state.user.IdProfile,
    getToken: state => state.token,
  },
  mutations: {
    auth_success(state, objauth) {
      state.token = objauth.token
      state.user = objauth.user
    },
    auth_user(state, user) {
      state.user = user
    },
    auth_denied(state) {
      state.user = {}
      state.token = ''
    },
  },
}
