import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { Plugin } from 'vue-fragment'

import { ColorPicker, ColorPanel } from 'one-colorpicker'
import router from './router'
import store from './store'
import App from './App.vue'
import eventBus from './plugins/eventBus'
import errorHandler from './plugins/handleErrorBus'
import dateFormat from './@core/filters/date-format'
import dateAge from './@core/filters/date-age'
import dateTimeFormat from './@core/filters/datetime-format'
import phoneFormat from './@core/filters/phone-format'
import timeFormat from './@core/filters/time-format'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(ColorPanel)
Vue.use(ColorPicker)
Vue.use(eventBus)
Vue.use(errorHandler)
Vue.use(dateFormat)
Vue.use(dateAge)
Vue.use(dateTimeFormat)
Vue.use(phoneFormat)
Vue.use(timeFormat)
Vue.use(Plugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
